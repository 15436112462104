<template lang="pug">
div
  .body(v-if='form.id > 0') 
    van-field(label='图示' colon readonly label-width='50px')
      template(#input)
        dp-image(:src='form.url' :urls='[form.url]' :index='0' width='100' height='100')
    van-field(label='名称' :value='form.name' colon readonly label-width='50px')
    van-field(label='色号' :value='form.code' colon readonly label-width='50px')
    van-field(v-show="form.info" type='textarea' autosize :rows='1' label='说明' :value='form.info' colon readonly label-width='50px')
    van-field(v-show="form.remark" type='textarea' autosize :rows='1' label='备注' :value='form.remark' colon readonly label-width='50px')
    van-field(label='场景图')
    van-col(:span="24")    
      dp-image.intro-pic(v-for='(img,index) in form.files' :key='index' :src='img' :urls='form.files' :index='index')
  loading-view(:value="loadingState")
  
</template>

<script>

export default {
  data () {
    return {
      loadingState: false,
      form: {}
    }
  },
  created () {
    this.form.id = this.$route.query.id
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.push({name: 'color'})
      },
      rightArrow: false,
    })
    this.init()
  },
  methods: {
    async init() {
      this.loadingState = true
      let params = {
        where: {
          id: this.form.id
        },
        order: '-order',
        with: {
          relation: [
            {
              table: 'gms_color_file',
              key: 'id_poi_gms_color',
              keys: 'id,url'
            }
          ]
        }
      }
      const res = await this.$api.GetColorCard(params)
      if (res.data.count > 0) {
        const { id_rel_gms_color_file, ...rest } = res.data.items[0]
        this.form = {
          ...rest,
          files: id_rel_gms_color_file.items.map(({url}) => url)
        }
      } else {
        this.form.id = 0
      }
      this.loadingState = false
    }
  },
}
</script>

<style lang="less" scoped>

.intro-pic {
  width: 100%;
  height: auto;
  margin-top: -5px;
}
</style>
